import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import Button from '@atlaskit/button';

import { QuickSummaryIcon } from '@confluence/quick-summary';

const i18n = defineMessages({
	buttonLabel: {
		id: 'ai-comments-summary.footer-comments-summary-button-placeholder.label.text',
		defaultMessage: 'Summarize page comments',
		description: 'Label text for the comments summary button placeholder in the footer',
	},
});

export const FooterCommentsSummaryButtonPlaceholder = () => {
	const intl = useIntl();

	return (
		<Button appearance="subtle" isDisabled iconBefore={<QuickSummaryIcon label="" />}>
			{intl.formatMessage(i18n.buttonLabel)}
		</Button>
	);
};

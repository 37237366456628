import { useQuery } from 'react-apollo';
import type { ApolloError } from 'apollo-client';

import type {
	CommentsSummaryQuery as CommentsSummaryQueryType,
	CommentsSummaryQueryVariables,
	SummaryType,
} from '../__types__/CommentsSummaryQuery';
import { CommentsType } from '../__types__/CommentsSummaryQuery';
import { CommentsSummaryQuery } from '../CommentsSummaryQuery.graphql';

export type CombinedCommentsSummariesData = {
	footerCommentsSummaryData: CommentsSummaryQueryType | undefined;
	inlineCommentsSummaryData: CommentsSummaryQueryType | undefined;
};

export type CombinedCommentsSummariesErrors = {
	footerCommentsSummaryError: ApolloError | undefined;
	inlineCommentsSummaryError: ApolloError | undefined;
};

type CombinedCommentsQueriesSummariesProps = {
	contentId: string;
	contentType: SummaryType;
	shouldSkipFooterCommentsQuery?: boolean;
	shouldSkipInlineCommentsQuery?: boolean;
};

type CombinedCommentsSummariesQueries = {
	data: CombinedCommentsSummariesData;
	errors: CombinedCommentsSummariesErrors;
	loading: boolean;
	refetch: () => void;
};

export const useCombinedCommentsSummariesQueries = ({
	contentId,
	contentType,
	shouldSkipFooterCommentsQuery = false,
	shouldSkipInlineCommentsQuery = false,
}: CombinedCommentsQueriesSummariesProps): CombinedCommentsSummariesQueries => {
	const {
		data: footerCommentsSummaryData,
		loading: footerCommentsQueryLoading,
		error: footerCommentsSummaryError,
		refetch: footerCommentsRefetch,
	} = useQuery<CommentsSummaryQueryType, CommentsSummaryQueryVariables>(CommentsSummaryQuery, {
		variables: {
			contentId,
			contentType,
			commentsType: CommentsType.FOOTER,
		},
		notifyOnNetworkStatusChange: true,
		skip: shouldSkipFooterCommentsQuery,
	});

	const {
		data: inlineCommentsSummaryData,
		loading: inlineCommentsQueryLoading,
		error: inlineCommentsSummaryError,
		refetch: inlineCommentsRefetch,
	} = useQuery<CommentsSummaryQueryType, CommentsSummaryQueryVariables>(CommentsSummaryQuery, {
		variables: {
			contentId,
			contentType,
			commentsType: CommentsType.INLINE,
		},
		notifyOnNetworkStatusChange: true,
		skip: shouldSkipInlineCommentsQuery,
	});

	const refetch = () => {
		void footerCommentsRefetch();
		void inlineCommentsRefetch();
	};

	return {
		data: {
			footerCommentsSummaryData,
			inlineCommentsSummaryData,
		},
		loading: footerCommentsQueryLoading || inlineCommentsQueryLoading,
		errors: {
			footerCommentsSummaryError,
			inlineCommentsSummaryError,
		},
		refetch,
	};
};

import { LoadableLazy } from '@confluence/loadable';

import { FooterCommentsSummaryButtonPlaceholder } from './FooterCommentsSummaryButtonPlaceholder';
export { CommentsSummarySource, SUMMARY_AUTOGENERATE_LIMIT } from './CommentsSummaryContent';

export const FooterCommentsSummaryButton = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-FooterCommentsSummary" */ './FooterCommentsSummaryButton'
			)
		).FooterCommentsSummaryButton,
	loading: FooterCommentsSummaryButtonPlaceholder,
});

export const FooterCommentsSummaryCombined = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-FooterCommentsSummaryCombined" */ './FooterCommentsSummaryCombined'
			)
		).FooterCommentsSummaryCombinedWithErrorBoundary,
});

import gql from 'graphql-tag';

export const CommentsSummaryQuery = gql`
	query CommentsSummaryQuery(
		$contentId: ID!
		$contentType: SummaryType!
		$commentsType: CommentsType!
	) {
		getCommentsSummary(
			contentId: $contentId
			contentType: $contentType
			commentsType: $commentsType
		) {
			contentId
			summary
			summaryId
			language
		}
	}
`;
